import {
    Jumbotron,
    JumbotronProps
} from 'common/components/organisms/Jumbotron'
import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'
import { Spacer } from '../molecules/Spacer'
import { Wrapper } from '../molecules/Wrapper'
import {
    FeatureList,
    FeatureListProps
} from '../organisms/FeatureList/FeatureList'
import { ProductList, ProductListProps } from '../organisms/ProductList'

export interface CSRProps {
    jumbotron: JumbotronProps
    layout: LayoutProps
    productList: ProductListProps
    productList2: ProductListProps
    featureList: FeatureListProps
}

export const CSR: React.FC<CSRProps> = ({
    jumbotron,
    layout,
    productList,
    featureList,
    productList2
}) => {
    const desktop = useDesktop()

    return (
        <Layout {...layout} box={{ bgcolor: '#fff' }}>
            <Jumbotron {...jumbotron} />
            {productList.title && <ProductList {...productList} />}
            <Wrapper>
                <Spacer variant={!desktop ? 'md' : 'lg'} />
                {featureList.title && (
                    <FeatureList variant='top' {...featureList} />
                )}
            </Wrapper>
            {productList2.title && <ProductList {...productList2} />}
        </Layout>
    )
}
