import { FeatureListProps } from 'common/components/organisms/FeatureList'
import { getJumbotronData } from 'common/components/organisms/Jumbotron'
import { CSR } from 'common/components/templates/CSR'
import { getLayoutData } from 'common/utils/getLayoutData'
import React from 'react'

export interface CsrPageProps {
    pageContext: any
}

export const CsrPage: React.FC<CsrPageProps> = ({ pageContext }) => {
    return <CSR {...getCSRData(pageContext)} />
}

export default CsrPage

export const getCSRData = (data: any) => ({
    layout: {
        ...getLayoutData(data),
        footer: {
            ...getLayoutData(data).footer,
            regLine: data?.footer__regulatory_text?.[0].text
        }
    },
    jumbotron: getJumbotronData(data),
    productList: {
        heading: data.productlist__heading,
        text: data.text,
        title: data.title,

        products: (data.products || []).map((product: any) => ({
            icon: product.icon,
            title: product.title,
            text: product.text,
            callToAction: {
                label: product.card_cta_0396ba7__cta_label,
                variant: product.card_cta_0396ba7__cta_variant,
                url: product.card_cta_0396ba7__cta_url
            }
        }))
    },
    featureList: {
        text: data.featurelist__text,
        cta: data.featurelist__cta,
        callToAction: {
            analyticsId: data.featurelist__cta_analyticsid,
            label: data.featurelist__cta_label,
            [`${data.call_to_action__iconlocation}Icon`]: data.featurelist__cta_icon,
            url: data.call_to_action__url,
            variant: data.call_to_action__variant,
            noSpacer: true
        },
        image: data.featurelist__image,
        title: data.featurelist__title,
        subtitle: data.featurelist__subtitle,
        features: data.featurelist__features,
        variant: data.featurelist__variant
            ? 'top'
            : (undefined as FeatureListProps['variant'])
    },
    productList2: {
        heading: data.product_list_2_heading,
        text: data.product_list_2_text,
        title: data.product_list_2_title,
        products: (data.products || []).map((product: any) => ({
            text: product.text,
            title: product.title,
            icon: product.icon,
            callToAction: {
                url: product.card_cta_0396ba7__cta_url,
                variant: product.card_cta_0396ba7__cta_variant,
                label: product.card_cta_0396ba7__cta_label
            }
        }))
    }
})
